// src/Main.js
import React from 'react';
import './Main.css';

const Main = () => {

    return (
        <div className="container">
            <div className="content">
                <section className="header">
                    <h1>Hey! I'm Aidan Mitchell</h1>
                    <p>Master’s student in Computer Science specializing in AI, with expertise in deep learning, neural networks, and advanced algorithms. Experienced in developing AI-driven projects and leading robotics initiatives. Seeking a role in AI research and development to create innovative solutions.</p>
                </section>
                <section className="links">
                    <div className="contact-info">
                        <div><i className="fa-solid fa-envelope"></i> mitchellaidan2@gmail.com</div>
                        <div><i className="fa-solid fa-phone"></i> 770-712-7205</div>
                        <div><i className="fa-solid fa-location-dot"></i> Georgia, USA</div>
                        <div>
                            <a href={process.env.PUBLIC_URL + "/documents/resume.pdf"} download="Aidan Mitchell Resume - June 2024.pdf" style={{color:'#333', fontWeight:'400'}}>
                                <i className="fa-solid fa-file-lines"></i> Resume
                            </a>
                        </div>
                    </div>
                    <div className="social-links">
                        <a href="https://github.com/AMitchell-GitHub"><i className="fab fa-github"></i> Github&nbsp;</a>
                        <a href="https://linkedin.com/in/aidantmitchell"><i className="fab fa-linkedin"></i> LinkedIn&nbsp;</a>
                    </div>
                </section>
                <section className="projects">
                    <a className="project-link" href="https://github.com/AMitchell-GitHub/Stock-Price-Prediction-DLNN">
                        <div className="project">
                            <div className="project-image">
                                <img src={process.env.PUBLIC_URL + "/images/stockPredictionDLNN.png"} alt="Project 1" />
                            </div>
                            <div className="project-info">
                                <h2>Deep Predictions: Harnessing Neural Networks for Stock Price Movement Forecasting</h2>
                                <p>This project investigates the integration of Long Short-Term Memory (LSTM) and Feed Forward Neural Network (FFNN) architectures to forecast short-term market movements in the technology sector of the American financial markets. Our model aims to outperform traditional linear financial forecasting methods by capturing both temporal and non-temporal market dynamics using advanced neural network techniques and rigorous data preprocessing.</p>
                            </div>
                        </div>
                    </a>
                    <a className="project-link" href="https://github.com/AMitchell-GitHub">
                        <div className="project">
                            <div className="project-image">
                                <img src={process.env.PUBLIC_URL +"/images/garchModel.png"} alt="Project 2" />
                            </div>
                            <div className="project-info">
                                <h2>Neural Networks for Volatility Estimation in Options Pricing: An Exploration</h2>
                                <p>This project aims to revolutionize financial modeling by integrating artificial intelligence with traditional methods. We combine neural networks with the GARCH model to enhance the binomial options pricing framework, focusing on improving volatility estimation. Using PyTorch for neural network implementation and 'yfinance' for data acquisition, we dynamically adjust GARCH model parameters, refining inputs for more accurate options pricing. This approach adapts to the complex nature of financial markets, showcasing AI's potential to transform traditional financial methodologies. Future steps include validating our AI-enhanced model against market data to ensure practical efficacy.</p>
                            </div>
                        </div>
                    </a>
                    <a className="project-link" href="https://github.com/arigold24k/CS7050_AI_Robotics_GroupProject">
                        <div className="project">
                            <div className="project-image">
                                <img src={process.env.PUBLIC_URL +"/images/depthEstimate.png"} alt="Project 3" />
                            </div>
                            <div className="project-info">
                                <h2>Residual Connection LSTM Single-Image Depth Estimation</h2>
                                <p>This project focuses on improving depth estimation from a single image by integrating Long Short-Term Memory (LSTM) with a double hourglass Convolutional Neural Network (CNN). By combining the temporal capabilities of LSTM with the spatial processing of the CNN, the method aims to address the challenge of lacking explicit depth information. This approach enhances depth estimation accuracy and robustness, outperforming existing methods.</p>
                            </div>
                        </div>
                    </a>
                    <a className="project-link" href="https://github.com/estevaobittencourt/VRP">
                        <div className="project">
                            <div className="project-image">
                                <img src={process.env.PUBLIC_URL +"/images/vehicleRoutingProblem.png"} alt="Project 4" />
                            </div>
                            <div className="project-info">
                                <h2>Exploration on Various Performant Techniques and Solutions for the VRP</h2>
                                <p>This project investigates optimization strategies for the Vehicle Routing Problem (VRP) by comparing three methodologies: brute-force, Simulated Annealing (SA), and Genetic Algorithm (GA). VRP is a complex challenge in logistics and transportation that aims to minimize costs and improve route planning efficiency. The project explores the theoretical foundations and practical applications of VRP, focusing on effective routing solutions to enhance operational efficiency. By evaluating brute-force, SA, and GA through computational experiments with benchmark datasets, the project assesses their performance, efficiency, and scalability in addressing various VRP complexities.</p>
                            </div>
                        </div>
                    </a>
                    <a className="project-link" href="https://docs.google.com/presentation/d/1_-OEM1_5C_SI66vYeFWP-AxEdN24bowgVPtGInbiX7w/edit?usp=sharing">
                        <div className="project">
                            <div className="project-image">
                                <img src={process.env.PUBLIC_URL +"/images/vehiclePackingProblem.png"} alt="Project 5" />
                            </div>
                            <div className="project-info">
                                <h2>3D Box Packing Problem - KSU Hackathon</h2>
                                <p>The project aimed to develop a program that optimizes the loading of packages into a confined space while adhering to weight limits. It considered factors such as the size and weight of boxes, the size of the loading space, and box orientations. The algorithm treated the problem as a 2D bin packing problem, adjusting layer heights dynamically to maximize space utilization. It included scenarios with varying package sizes and weights, and implemented a priority system based on lead times and market volatility to ensure efficient product shipping.</p>
                            </div>
                        </div>
                    </a>
                </section>
            </div>
        </div>
    );
}

export default Main;
